import React from "react"
import { Link } from "gatsby"
import frankProfilePhoto from "../images/frank-russo2.png"
import gregProfilePhoto from "../images/greg-white2.png"
import robbProfilePhoto from "../images/robb-keller.png"
import { Row, Col } from "react-bootstrap"

export default () => (
  <Row>
    <Col xs={12} sm={4}>
      <div
        style={{
          background:
            "radial-gradient(circle, rgba(238,238,238,1) 0%, rgba(0,0,0,1) 100%)",
        }}
        className="border scaleUp"
      >
        <img src={frankProfilePhoto} className="img-fluid" alt="Frank Russo" />

        <div className="bg-primary px-2 py-3 d-flex justify-content-around heading text-white text-uppercase">
          <div>
            <Link
              to="/attorneys/frank-russo"
              className="stretched-link text-white"
            >
              Frank J. Russo
            </Link>
          </div>

          <div>Partner</div>
        </div>
      </div>
    </Col>
    <Col xs={12} sm={4}>
      <div
        className="my-4 my-sm-0"
        style={{
          background:
            "radial-gradient(circle, rgba(238,238,238,1) 0%, rgba(0,0,0,1) 100%)",
        }}
        className="border scaleUp"
      >
        <img src={gregProfilePhoto} className="img-fluid" alt="Greg White" />

        <div className="bg-primary px-2 py-3 d-flex justify-content-around heading text-white text-uppercase">
          <div>
            <Link
              to="/attorneys/greg-white"
              className="stretched-link text-white"
            >
              Greg White
            </Link>
          </div>

          <div>Partner</div>
        </div>
      </div>
    </Col>
    <Col xs={12} sm={4}>
      <div
        style={{
          background:
            "radial-gradient(circle, rgba(238,238,238,1) 0%, rgba(0,0,0,1) 100%)",
        }}
        className="border scaleUp"
      >
        <img src={robbProfilePhoto} className="img-fluid" alt="Robb Keller" />

        <div className="bg-primary px-2 py-3 d-flex justify-content-around heading text-white text-uppercase">
          <div>
            <Link
              to="/attorneys/robert-keller"
              className="stretched-link text-white"
            >
              Robert Keller
            </Link>
          </div>

          <div>Partner</div>
        </div>
      </div>
    </Col>
  </Row>
)
